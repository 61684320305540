<script setup lang="ts">
import type { UtilsSnackbarProps } from './UtilsSnackbar.props'
const props = withDefaults(defineProps<UtilsSnackbarProps>(), {
  duration: 7,
  showIcons: true,
})

const emit = defineEmits(['update:action', 'update:close'])

const snackbar = ref<HTMLElement | null>(null)
onMounted(() => {
  nextTick(() => {
    snackbar.value?.focus()
    if (snackbar.value) {
      snackbar.value.setAttribute('tabindex', '-1')
      snackbar.value.focus()
    }
  })
})
const labelBy = computed(() => {
  return props?.message?.replace(/[^a-zA-Z0-9]/g, '')
})
</script>

<template>
  <div
    ref="snackbar"
    class="utils-snackbar relative w-full"
    :aria-labelledby="labelBy"
    aria-live="polite"
  >
    <div
      id="snackbar-undo"
      class="text-book-6 bg-primitives-grey-50 flex w-full items-center p-4"
    >
      <DefaultIconsInfo
        v-if="showIcons"
        :class="['h-5 w-5 shrink-0', textClasses]"
        aria-hidden="true"
      />
      <div class="pl-2">
        <p :id="labelBy" :class="textClasses">
          {{ message }}
        </p>
      </div>
      <div
        class="ms-auto flex items-center"
        :class="[
          isFeedback
            ? 'gap-x-sm justify-between'
            : 'space-x-2 rtl:space-x-reverse',
        ]"
      >
        <AtomsCta
          v-if="actionMessage"
          anatomy="link"
          class="pl-4"
          :data-testid="actionMessage"
          :aria-label="
            $ts('aria-labels.snackbar.updateAction', { actionMessage })
          "
          @click.stop.prevent="emit('update:action')"
        >
          <template #label>
            {{ actionMessage }}
          </template>
        </AtomsCta>
        <button
          v-if="isFeedback"
          :aria-label="$ts('aria-labels.snackbar.updateClose')"
          type="button"
          @click="emit('update:close')"
        >
          <DefaultIconsClose class="h-5 w-5 shrink-0" aria-hidden="true" />
        </button>
      </div>
    </div>

    <div v-if="duration > 0" class="bg-primitives-grey-150 h-0.5">
      <div
        class="bg-neutral-black h-0.5 origin-left animate-[progress_linear]"
        :style="{
          animationDuration: `${duration}s`,
        }"
      />
    </div>
  </div>
</template>

<style scope lang="scss">
@keyframes progress {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
</style>
